export const MODAL = {
  accountRecoveryPassword: 'account_recovery_password',
  auth: {
    signIn: 'sign_in',
    signUp: 'sign_up',
    confirm: 'confirm_email',
    activate: 'activate',
    activateSuccess: 'activate_success',
  },
  country: {
    main: 'country_main',
    select: 'country_select',
  },
  exit: 'exit',
  message: 'message',
  organizers: 'organizers',
  recoveryPassword: {
    email: 'password_recovery_mail',
    code: 'password_recovery_code',
    renew: 'password_recovery_renew',
  },
  selection: 'selection',
  success: 'success',
  video: 'video',
  subscription: 'subscription',
};
